@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Light.eot');
  src: url('../../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
      url('../../fonts/Roboto/Roboto-Light.woff') format('woff'),
      url('../../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Bold.eot');
  src: url('../../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
      url('../../fonts/Roboto/Roboto-Bold.woff') format('woff'),
      url('../../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Medium.eot');
  src: url('../../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
      url('../../fonts/Roboto/Roboto-Medium.woff') format('woff'),
      url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Regular.eot');
  src: url('../../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
      url('../../fonts/Roboto/Roboto-Regular.woff') format('woff'),
      url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Black.eot');
  src: url('../../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
      url('../../fonts/Roboto/Roboto-Black.woff') format('woff'),
      url('../../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

