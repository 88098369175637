/* Light Gallery */

.lightGallery{
  width: 100%;
  margin: 0;
  .image-tile{
    position: relative;
    @extend .col-xl-3;
    @extend .col-lg-3;
    @extend .col-md-3;
    @extend .col-md-4;
    @extend .col-6;
    margin-bottom: 30px;
    .demo-gallery-poster{
      @extend .d-flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      img{
        display: block;
        margin: auto;
        width: 40%;
        max-width: 60px;
        min-width: 20px;
      }
    }
    img{
      max-width: 100%;
      width: 100%;
    }
  }
}
