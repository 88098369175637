/* Listify */

.listify-list{
  input{
    border: 1px solid $white-smoke;
    color: color(gray-light);
    background: #fff;
  }
  button{

  }
  ul.list{
    list-style: none;
    padding-left: 0;
    li{
      display: block;
      border-bottom: $border-property;
      padding: 15px 10px;
      h5{
        color: theme-color("primary");
      }
      p{
        color: color(gray-light);
        margin: 0;
      }
    }
  }
}
