/* TinyMCE Editor */

.mce-tinymce {
  &.mce-panel,
  .mce-panel {
    border-color: $border-color;
  }
  .mce-panel {
    background: $input-bg;
    color: $input-color;
  }
}
