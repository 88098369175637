////////// SIDEBAR ////////
$sidebar-width-lg: 255px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;


$sidebar-bg: #131633;
$sidebar-menu-color: #8c909a;
$sidebar-menu-active-bg: initial;
$sidebar-menu-active-color: theme-color(primary);
$sidebar-menu-hover-bg: #191e3c;
$sidebar-menu-hover-color: #d0cfcf;
$sidebar-submenu-color: $sidebar-menu-color;
$sidebar-submenu-hover-bg: initial;
$sidebar-submenu-hover-color: #bebdc2;
$sidebar-submenu-active-color: #c2c2c2;
$sidebar-menu-icon-color: #656a8f;
$sidebar-menu-arrow-color: #656a8f;

$sidebar-menu-font-size: 0.937rem;
$sidebar-menu-padding-left: 1.25rem;
$sidebar-menu-padding-right: 1.875rem;
$sidebar-menu-padding-top: .75rem;
$sidebar-menu-padding-bottom: .75rem;

$sidebar-submenu-padding: .25rem 0 0 3.75rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: 0.75rem;

$sidebar-icon-font-size:  1rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg: $sidebar-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: #131633;
$navbar-height: 65px;
$navbar-menu-color: #b1b1b5;
$navbar-font-size: .875rem;
$navbar-icon-font-size: .9375rem;

///////// NAVBAR ////////