/* Google Charts */

.google-chart-container {
  .google-charts {
    width: 100%;
    height: 280px;
    min-height: 280px;
    min-width: 100%;
  }
}
