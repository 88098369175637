@each $color, $value in $social-colors {
  .bg-#{$color} {
    background: social-color($color);
  }
}

@each $color, $value in $theme-gradient-colors {
  .bg-gradient-#{$color} {
    background: $value;
  }
}