/* Ace Editor */

.ace_editor {
		margin: auto;
		height: 300px;
		width: 100%;
		font: 14px/normal SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
		.ace_content{
			font-size: $default-font-size;
		}
}
