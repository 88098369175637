/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\F140";  
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: 0.875rem;
      margin-left: 0;
    }
  }
  .dropdown-menu {
    font-size: $default-font-size;
    border-radius: 0;   
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    .dropdown-item {
      font-size: .875rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
      &:hover {
        color: #dfe3e9;
        background: #1e213c;
      }
    }
  }
}
